<template>
  <v-card @click="goToProfile" class="rounded-card">
    <div
      class="agency-card-content pa-5 d-flex flex-row"
      style="justify-content: flex-start"
    >
      <agency-avatar
        :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
        :name="agency.name"
        :size="60"
      />
      <span class="agency-title ml-4">
        {{ agency.name }}
      </span>
    </div>
    <v-btn
      block
      style="max-height: 40px; height: 40px;"
      :color="getFanned ? '#A1CC33' : '#0A5B8A'"
      dark
      class="bottom-button"
      @click.stop="becameFan"
      :loading="loading"
    >
      {{ getFanned ? "Unfollow" : "Follow" }}
    </v-btn>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
export default {
  components: { AgencyAvatar },
  props: {
    agency: Object
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type"
    }),
    getCompletedInfo() {
      var str = "";
      if (!this.agency.image) str += "Logo";
      if (!this.agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!this.agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!this.agency.contact_person || !this.agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!this.agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.agency.address) str ? (str += ", Address") : (str = "Address");
      if (!this.agency.city) str ? (str += ", City") : (str = "City");
      if (!this.agency.state) str ? (str += ", State") : (str = "State");
      if (!this.agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
    getFanned() {
      if (!this.profile.followers) return false;
      if (this.profile.followers.includes(this.agency._id)) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("agency", {
      followAgency: "followAgency"
    }),
    goToProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.agency._id }
      });
    },
    becameFan() {
      this.loading = true;
      var fanned = this.getFanned;
      this.followAgency({ followed: !fanned, agency: this.agency._id })
        .then(() => {
          this.loading = false;
          console.log(this.profile);
        })
        .catch(error => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    }
  }
};
</script>
<style>
.agency-title {
  color: #472583;
  font-size: 13px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 15px !important;
  max-height: 30px;
  min-height: 30px;
  height: 30px;
  overflow: hidden;
  align-content: center;
}
.agency-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.rounded-card {
  border-radius: 20px !important;
}
.rounded-card:focus::before {
  border-radius: 20px !important;
}
.bottom-button {
  border-radius: 0 0 20px 20px;
}
</style>
